import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { Toast } from 'antd-mobile';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import WXAuthUtil from '../../../../utils/WXAuthUtil';
import qs from 'qs';

class AuthQueryBlank extends Component {

  componentWillMount() {
    const searchString = window.location.search;
    
    if (searchString === '') {
      window.location.href = WXAuthUtil.AUTH_QUERY_AUTH_URLT;
    }
  }

  componentDidMount() {
    const searchString = window.location.search;

    // searchString 不为空
    if (!!searchString) {
      const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
      const code = searchObj.code;
      this.props.loadWXAuthInfo(code);
    }
  }

  componentDidUpdate() {
    const {
      loadWXAuthInfoActionStatus,
      wxAuthInfo,

      // UserInfo By OpenId
      loadUserInfoActionStatus,
      loadUserInfoResult,
    } = this.props;

    // 查询基金授权信息
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadWXAuthInfoActionStatus();

      if (wxAuthInfo.hasOwnProperty('errcode')) {
        // window.location.href = WXAuthUtil.AUTH_QUERY_AUTH_URLT;
        // return;
        this.props.setAuthQueryBlankFailed('Failed to request Open ID: ' + wxAuthInfo.errcode + ', ' + wxAuthInfo.errmsg);
        return;
      }
      const openId = wxAuthInfo.openid;
      this.props.loadUserInfo(openId);
    }
    
    if (loadWXAuthInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadWXAuthInfoActionStatus();
      this.props.setAuthQueryBlankFailed('Failed to request Open ID: Internal Error');
    }

    // 查询微信openId关联的用户信息
    if (loadUserInfoActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadUserInfoActionStatus();

      const userInfo = loadUserInfoResult.resultData;
      const userGuid = userInfo.guid;
      const openId = userInfo.openId;
      const mobileNo = userInfo.mobileNo;
      const userName = userInfo.name;

      this.props.history.replace({
        pathname: '/home/auth-queryT', 
        state: {
          openId,
          userGuid,
          mobileNo,
          userName,
          authTabIdx: 0,
        }
      });
    }
    
    if (loadUserInfoActionStatus === ACTION_STATUS.ERROR) {
      this.props.initLoadUserInfoActionStatus();
      
      const openId = wxAuthInfo.openid;
      this.props.history.replace({
        pathname: '/home/risk-evaluation', 
        state: {
          openId,
        }
      });
    }
  }

  componentWillUnmount() {
    Toast.hide();
  }


  reloadCurrPage() {
    this.props.resetAuthQueryBlankStatus();
    window.location.href = WXAuthUtil.AUTH_QUERY_AUTH_URLT;
  }

  render() {
    const {
      authQueryBlankActionStatus,
      authQueryBlankActionResp,
    } = this.props;

    const showErr = authQueryBlankActionStatus === ACTION_STATUS.ERROR;
    if(showErr) {
      Toast.hide();
    } else {
      Toast.loading('Loading ... ', 0);
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>保银投资客户服务</title>
        </Helmet>
        {showErr ? (
          <div>
              <div>{authQueryBlankActionResp}</div>
              <div><a href='#' onClick={()=>this.reloadCurrPage()}>加载失败，请点击这里重新加载</a></div>
          </div>
        ) : ""}
      </div>
    );
  }
}

export default AuthQueryBlank;